export function parseErrorMessage(message) {
    switch (message) {
        case 'USER_DOES_NOT_EXIST':
            return 'Usuario no existe'
        case 'NOT_FOUND':
            return 'No encontrado'
        case 'EMAIL_IS_NOT_VALID':
        case 'WRONG_PASSWORD':
            return 'Email o contraseña inválido'
        case 'COUPON_CLAIMED':
            return 'Este cupón ya ha sido usado'
        case 'COUPON_EXPIRED':
            return 'Este cupón ya ha expirado'
        case 'BLOCKED_USER':
            return 'Usuario bloqueado'
        case 'ID_MALFORMED':
            return 'ID inválido'
        case 'PASSWORD_TOO_SHORT_MIN_5':
            return 'Contraseña muy corta'
        default:
            return message
    }
}

export function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export function parseMedicinesToSelect(data) {
    const medicines = []
    for (const item of data) {
        medicines.push({
            value: item._id,
            key: item._id,
            label: capitalize(item.name),
        })

        for (const [index, tag] of item.tags.entries()) {
            medicines.push({
                value: `${item._id}__${index}`,
                key: `${item._id}__${index}`,
                label: `${capitalize(tag)} - (${capitalize(item.name)})`,
            })
        }
    }

    return medicines
}
