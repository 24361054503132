import React, { useEffect } from 'react'
import { Button } from 'antd'
import './style.css'

const Convenio = (props) => {
    useEffect(() => {}, [])

    return (
        <>
            <div className="question">
                <h3>¿Quieres saber cómo acceder a la cobertura de medicamentos?</h3>
                <div>
                    <Button type="primary" onClick={props.onClick}>
                        Mira aquí
                    </Button>
                </div>
            </div>
        </>
    )
}

export default Convenio
