import axios from 'axios'
import { notification } from 'antd'
import { parseErrorMessage } from '../utils'

const service = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60000,
})

// API respone interceptor
service.interceptors.response.use(
    (response) => {
        return response.data
    },
    (error) => {
        if (Array.isArray(error.response.data?.errors?.msg)) {
            const message = parseErrorMessage(error.response.data?.errors?.msg[0]?.msg)

            notification['error']({
                message: message || 'Ha ocurrido un error',
                description: 'Por favor intente más tarde.',
                className: 'error',
            })
            return Promise.reject(error)
        }

        if (error.response.data?.errors?.msg) {
            const message = parseErrorMessage(error.response.data?.errors?.msg)

            notification['error']({
                message: message || 'Ha ocurrido un error',
                description: 'Por favor intente más tarde.',
            })
        }

        return Promise.reject(error)
    }
)

export default service
