import fetch from './FetchInterceptor'

const service = {}

service.getMedicines = function (params) {
    return fetch({
        url: `/consults/medicines`,
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

service.getFaqs = function () {
    return fetch({
        url: `/consults/faqs`,
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

service.getCoverage = function (data) {
    return fetch({
        url: `/consults/coverage`,
        method: 'post',
        data,
    })
}

service.getTexts = function () {
    return fetch({
        url: `/texts/1`,
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

service.postFeedback = function (data) {
    return fetch({
        url: `/consults/feedback`,
        method: 'post',
        data,
    })
}

service.postCoverage = function (data) {
    return fetch({
        url: `/consults/survey-coverage`,
        method: 'post',
        data,
    })
}

export default service
