import React, { useEffect } from 'react'
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import './style.css'

const BackHome = () => {
    useEffect(() => {}, [])

    return (
        <>
            <Row justify="center" className="own-back-home">
                <Col span={24}>
                    <div className="back center">
                        <Link to={`/`}>Volver al inicio</Link>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default BackHome
