import React, { useEffect, useState, useCallback } from 'react'
import MainService from '../services/MainService'
import ReactHtmlParser from 'react-html-parser'
import Loader from '../components/utils/Loader'
import { CaretRightOutlined } from '@ant-design/icons'
import { Collapse } from 'antd'
import { Link } from 'react-router-dom'
const { Panel } = Collapse

const Response = (props) => {
    const [loading, setLoading] = useState(true)
    const [faqs, setFaqs] = useState([])

    const getData = useCallback(async () => {
        try {
            const data = await MainService.getFaqs()
            setFaqs(data)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        try {
            getData()
        } catch (error) {
            setLoading(false)
        }
    }, [getData])

    if (loading) {
        return <Loader center padding={'5rem 1rem'} />
    }

    return (
        <section className="faqs">
            <div className="container-s">
                <div className="wrapper">
                    <h1>Preguntas Frecuentes</h1>
                    <div className="faqsContainer">
                        <Collapse
                            defaultActiveKey={[0]}
                            ghost
                            expandIcon={({ isActive }) => (
                                <CaretRightOutlined
                                    rotate={isActive ? 90 : 0}
                                />
                            )}
                        >
                            {faqs.map((faq, i) => (
                                <Panel header={faq.question} key={i}>
                                    <p>{ReactHtmlParser(faq.answer)}</p>
                                </Panel>
                            ))}
                        </Collapse>
                    </div>
                    <div className="back center">
                        <Link to={`/`}>Volver al inicio</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Response
