import React, { useEffect } from 'react'
import { Row, Col } from 'antd'
import BackHome from '../components/utils/BackHome'
import SourceCoverage from '../components/utils/SourceCoverage'
import './style.css'

const Isapre = () => {
    useEffect(() => {}, [])

    return (
        <>
            <Row justify="center">
                <Col md={6} xs={22}>
                    <img className="own-global-convenio" src="https://i.ibb.co/bmzNzj3/isapre.jpg" alt="isapre" style={{ width: '100%' }}></img>
                    <SourceCoverage />
                    <BackHome />
                </Col>
            </Row>
        </>
    )
}

export default Isapre
