import React, { useEffect } from 'react'
import './style.css'

const SourceCoverage = () => {
    useEffect(() => {}, [])

    return (
        <>
            <p>Fuentes:</p>
            <ul>
                <li>
                    <a href="https://www.bcn.cl/leychile/navegar?idNorma=229834">Ley 19.966</a>
                </li>
                <li>
                    <a href="https://www.supersalud.gob.cl/difusion/665/w3-propertyvalue-1962.html">Superintendencia de Salud</a>
                </li>
            </ul>
        </>
    )
}

export default SourceCoverage
