import React, { useState, useEffect, useCallback } from 'react'
import { Form, Button, Radio, Input, Checkbox } from 'antd'
import MainService from '../services/MainService'
import ReactGA from 'react-ga'
import { useNavigate } from 'react-router-dom'
import check from './../assets/img/check.svg'
import Convenio from '../components/utils/Convenio'

function FormFeedback(props) {
    const navigate = useNavigate()
    const [data] = useState(props.data)
    const [initialValues, setInitialValues] = useState([])
    const [loading, setLoading] = useState(false)
    const [other, setOther] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const setFormInitialValues = useCallback(async () => {
        setInitialValues([
            {
                name: ['age'],
                value: '',
            },
            {
                name: ['gender'],
                value: 'male',
            },
        ])
    }, [])

    const onChange = (checkedValues) => {
        if (checkedValues.includes('other')) {
            setOther(true)
        } else {
            setOther(false)
        }
    }

    async function onFinish(values) {
        setLoading(true)
        const toSend = {
            ...values,
            ...props.data,
        }

        await MainService.postFeedback(toSend)
        ReactGA.event({
            category: 'user',
            action: 'sent feeback',
        })
        setLoading(false)
        setShowSuccess(true)
    }

    const handleGoSuccess = () => {
        navigate({
            pathname: `/${data.system}`,
        })
    }

    useEffect(() => {
        setFormInitialValues()
        console.log('FormFeedback', props.data)
    }, [setFormInitialValues, props])

    return (
        <section className="formFeedback">
            <>
                {showSuccess === false && (
                    <>
                        <h3 style={{ textAlign: 'center', marginBottom: '16px' }}>¡Completa tus datos y te contamos cómo usar tu cobertura!</h3>

                        <Form className="form" fields={initialValues} onFinish={onFinish} layout="vertical">
                            <Form.Item name="age" rules={formRules.age} style={{ marginBottom: '16px' }} label="Edad">
                                <Input type={'number'} placeholder={'Años'} />
                            </Form.Item>

                            <Form.Item name="gender" rules={formRules.gender} style={{ marginBottom: '16px' }} label="Género">
                                <Radio.Group>
                                    <Radio value={'male'}>Masculino.</Radio>
                                    <Radio value={'female'}>Femenino.</Radio>
                                    <Radio value={'other'}>Otro.</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item name="reasons" rules={formRules.reasons} style={{ marginBottom: '16px' }} label="Selecciona una o mas alternativas:">
                                <Checkbox.Group
                                    style={{
                                        width: '100%',
                                    }}
                                    onChange={onChange}
                                >
                                    <Checkbox value="No sabía que tenía cobertura y tuve que comprarlo">
                                        No sabía que tenía cobertura y tuve que comprarlo
                                    </Checkbox>
                                    <Checkbox value="El medicamento no está disponible en el centro de salud donde me atiendo">
                                        El medicamento no está disponible en el centro de salud donde me atiendo
                                    </Checkbox>
                                    <Checkbox value="Había demasiada gente en la fila y no podía esperar">
                                        Había demasiada gente en la fila y no podía esperar
                                    </Checkbox>
                                    <Checkbox value="El trámite para obtener el beneficio es muy complejo">
                                        El trámite para obtener el beneficio es muy complejo
                                    </Checkbox>
                                    <Checkbox value="No tengo receta">No tengo receta</Checkbox>
                                    <Checkbox value="Prefiero comprar el medicamento sin el beneficio">
                                        Prefiero comprar el medicamento sin el beneficio
                                    </Checkbox>
                                    <Checkbox value="other">Otra</Checkbox>
                                </Checkbox.Group>
                            </Form.Item>

                            {other && (
                                <Form.Item name="other" rules={formRules.other} style={{ marginBottom: '16px', marginTop: '-30px' }}>
                                    <Input />
                                </Form.Item>
                            )}

                            <Button type="primary" htmlType="submit" loading={loading}>
                                Enviar
                            </Button>
                        </Form>
                    </>
                )}

                {showSuccess === true && (
                    <>
                        <section className="success">
                            <div className="container-s">
                                <div className="content">
                                    <img src={check} alt="logo" className="success" />
                                    <h2>¡Muchas Gracias!</h2>
                                    <h3>Hemos recibido su respuesta</h3>
                                </div>
                                <br></br>
                                <Convenio onClick={() => handleGoSuccess()} />
                            </div>
                        </section>
                    </>
                )}
            </>
        </section>
    )
}

const formRules = {
    age: [
        {
            required: true,
            message: 'Edad es requerida',
        },
    ],
    gender: [
        {
            required: true,
            message: 'Género es requerida',
        },
    ],
    reasons: [
        {
            required: true,
            message: 'Seleccione al menos un motivo',
        },
    ],
    other: [
        {
            required: true,
            message: 'Campo es requerido',
        },
    ],
}

export default FormFeedback
