import React from 'react'
import { Layout } from 'antd'
import { Link } from 'react-router-dom'
import './style.css'
import Loader from '../components/utils/Loader'
const { Header, Footer, Content } = Layout
const LayoutMain = (props) => (
    <div className={`layout-main`}>
        <Layout>
            <Header>
                <Link to={`/`}>
                    <img src={require('./../assets/img/logo_white.png').default} alt="logo" />
                </Link>
            </Header>
            <Content>{props.children}</Content>
            <Footer>
                <div className="container">
                    <p className="own-layout-footer-info">Información de cobertura de medicamentos proporcionada por:</p>
                    <a href="https://farma-erp.cl/">
                        <img className="partner" src="https://i.ibb.co/pJsWnCt/erp.png" alt="colaborador" />
                    </a>
                    {props.loading ? <Loader center padding={'1rem'} /> : <p>{props.texts?.responsability}</p>}
                    <Link to={`/faqs`} className="own-layout-aref">
                        Preguntas Frecuentes
                    </Link>
                    <br></br>
                </div>
                <p className="own-layout-footer-version">V 2.0 - 28/02/2023</p>
            </Footer>
        </Layout>
    </div>
)

export default LayoutMain
