import React, { useState, useEffect, useCallback } from 'react'
import { Form, Button, Radio, Select, Spin } from 'antd'
import { useDebouncedCallback } from 'use-debounce'
import MainService from '../services/MainService'
import { parseMedicinesToSelect } from '../utils'
import { useNavigate } from 'react-router-dom'
import './style.css'
const Home = () => {
    const [loading, setLoading] = useState(false)
    const [initSearch, setInitSearch] = useState(false)
    const [loadingMedicines, setLoadingMedicines] = useState(false)
    const [medicinesOptions, setMedicinesOptions] = useState([])
    const [initialValues, setInitialValues] = useState([])
    const [medicineTag, setMedicineTag] = useState('')
    const navigate = useNavigate()

    const setFormInitialValues = useCallback(async () => {
        setInitialValues([
            {
                name: ['system'],
                value: 'fonasa',
            },
        ])
    }, [])

    const getMedicines = useCallback(async (query = '') => {
        const fetchMedicines = await MainService.getMedicines({
            filter: query,
            fields: 'name,tags',
            limit: 100,
        })
        const medicines = parseMedicinesToSelect(fetchMedicines.docs)
        setMedicinesOptions(medicines)
        setInitSearch(true)
        setLoadingMedicines(false)
    }, [])

    const debouncedGetMedicines = useDebouncedCallback(getMedicines, 500)

    const onSearch = (e) => {
        setLoadingMedicines(true)
        debouncedGetMedicines(e)
    }

    async function onFinish(values) {
        setLoading(true)
        const toSend = {
            medicine: values.medicine.key.split('__')[0],
            system: values.system,
            tag: medicineTag.split(' - ')[0],
        }
        navigate({
            pathname: '/response',
            search: `?medicine=${toSend.medicine}&system=${toSend.system}&tag=${toSend.tag}`,
        })
        setLoading(false)
        try {
        } catch (error) {
            setLoading(false)
        }
    }

    const handleChange = (value = '') => {
        setMedicineTag(value.label)
        setInitSearch(false)
    }

    useEffect(() => {
        async function getMedicineData() {
            setLoading(true)
            setFormInitialValues()
            setLoading(false)
        }

        getMedicineData()
    }, [setFormInitialValues])

    return (
        <section className="home">
            <div className="container-s">
                <div className="wrapper">
                    <h1>Busca si tu medicamento tiene cobertura*</h1>
                    <h2>Selecciona tu sistema de salud, elige tu medicamento y pincha buscar.</h2>
                    <Form className="form" fields={initialValues} onFinish={onFinish}>
                        <Form.Item name="system" rules={formRules.system} style={{ marginBottom: '16px' }}>
                            <Radio.Group>
                                <Radio value={'fonasa'}>Soy fonasa</Radio>
                                <Radio value={'isapre'}>Soy isapre</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item name="medicine" rules={formRules.medicine} style={{ width: '100%', maxWidth: '410px' }}>
                            <Select
                                filterOption={false}
                                allowClear
                                onSearch={onSearch}
                                loading={loadingMedicines}
                                options={medicinesOptions}
                                onChange={handleChange}
                                labelInValue={true}
                                showSearch={true}
                                placeholder="Ej: Salbutamol..."
                                notFoundContent={
                                    loadingMedicines ? (
                                        <Spin size="small" />
                                    ) : (
                                        initSearch && (
                                            <p
                                                style={{
                                                    color: 'blue',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                Este medicamento no se encuentra en el listado de programas de cobertura
                                            </p>
                                        )
                                    )
                                }
                            ></Select>
                        </Form.Item>

                        <Button type="primary" htmlType="submit" loading={loading}>
                            Buscar
                        </Button>
                    </Form>
                    <div className="disclaimer">
                        <p>
                            <b>*Cobertura de un medicamento:</b> Valor que financia o bonifica total o parcialmente tu sistema de salud, ya seas{' '}
                            <a href="/fonasa" className="own-home-link">
                                Fonasa
                            </a>{' '}
                            o{' '}
                            <a href="/isapre" className="own-home-link">
                                isapre
                            </a>
                            .
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

const formRules = {
    system: [
        {
            required: true,
            message: 'Previsión de salud es requerida',
        },
    ],
    medicine: [
        {
            required: true,
            message: 'Seleccione un medicamento por favor',
        },
    ],
}

export default Home
